@media (max-width: 600px) {

    .fade-in-right {
        opacity: 1;
        transform: translateX(30px);
        transition: transform 0.5s ease-in-out;
    }

    .fade-in-right.visible {
        opacity: 1;
        transform: translateX(0px);
    }
}

@media all and (min-width: 600px) and (orientation:landscape) {
    .fade-in-right {
        opacity: 1;
        transform: translateX(300px);
        transition: transform 0.5s ease-in-out;
    }

    .fade-in-right.visible {
        opacity: 1;
        transform: translateX(0px);
    }
}