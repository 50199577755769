@media (max-width: 600px) {

    .fade-in-left {
        opacity: 1;
        transform: translateX(-30px);
        transition: transform 0.5s ease-in-out;

    }

    .fade-in-left.visible {
        opacity: 1;
        transform: translateX(0px);
    }
}
@media all and (min-width: 600px) and (orientation:landscape) {
    .fade-in-left {
        opacity: 1;
        transform: translateX(-300px);
        transition: transform 0.5s ease-in-out;
    }

    .fade-in-left.visible {
        opacity: 1;
        transform: translateX(0px);
    }

}
