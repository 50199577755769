@media (max-width: 600px) {
    .estimate_container {
        display: flex;
        width: 100%;
        height: auto;
        background: url("../../Assets/estimate_bg_crop.jpg") center;
        background-size: cover;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding-top: 70px;
        padding-bottom: 70px;


        .estimate_form {
            width: auto;
            height: auto;
            padding: 2%;
            margin-top: 20px;
        }

        .estimate_text {
            display: flex;
            flex-direction: column;
            width: auto;
            padding: 3%;

            .title {
                font-size: 18px;
                color: whitesmoke;
                letter-spacing: 3px;
                z-index: 50;
                font-family: 'Alegreya Sans 900 Black', sans-serif;
                font-weight: bolder;
                width: auto;
                text-align: center;
                word-wrap: break-word;
            }

            .caption {
                width: auto;
                font-size: 15px;
                color: whitesmoke;
                letter-spacing: 3px;
                font-family: 'Alegreya Sans', sans-serif;
                text-align: center;
                word-wrap: break-word;
            }

        }
    }
}


@media all and (min-width: 600px) and (orientation:landscape) {
    .estimate_container {
        display: flex;
        width: 100%;
        min-height: 90vh;
        background: url("../../Assets/estimate_bg_crop.jpg") center;
        background-size: 100% 100%;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;


        .estimate_form {
            width: auto;
            height: auto;
            padding: 2%;
            margin-left: 20px;
        }

        .estimate_text {
            display: flex;
            flex-direction: column;
            width: auto;
            max-width: 45%;
            padding: 2%;
            margin-right: 20px;

            .title {
                font-size: 80px;
                color: whitesmoke;
                letter-spacing: 3px;
                z-index: 50;
                font-family: 'Alegreya Sans 900 Black', sans-serif;
                font-weight: bolder;
                width: auto;
                text-align: end;
                word-wrap: break-word;
            }

            .caption {
                width: auto;
                font-size: 60px;
                color: whitesmoke;
                letter-spacing: 3px;
                font-family: 'Alegreya Sans', sans-serif;
                text-align: end;
                word-wrap: break-word;
            }

        }

    }
}

