@media all and (min-width: 600px) and (orientation:landscape)  {

    .footer {
        background: black;
        height: auto;
        width: 100%;

        .footer_container {
            padding: 20px 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 5px;

            .right_container {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;


                .logo {
                    margin-right: 20px;
                    height: 100px;
                    width: auto
                }

                .right_text {
                    font-size: 15px;
                    color: whitesmoke;
                    margin-left: 30px;
                    letter-spacing: 2px;
                    font-family: 'Alegreya Sans 900 Black', sans-serif;
                }
            }

            .contact_container {
                margin-left: 20px;
                width: auto;
                height: auto;
                display: flex;
                color: whitesmoke;
                font-family: 'Alegreya Sans 900 Black', sans-serif;
                letter-spacing: 2px;
                font-size: 30px;
                flex-direction: column;

            }
        }
    }
}


@media (max-width: 600px) {
    .footer {
        background: black;
        height: auto;
        width: 100%;

        .footer_container {
            padding: 20px 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 5px;
            flex-direction: column-reverse;

            .right_container {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                flex-direction: column-reverse;


                .logo {
                    height: 100px;
                    width: auto
                }

                .right_text {
                    font-size: 10px;
                    color: whitesmoke;
                    margin-left: 30px;
                    letter-spacing: 2px;
                    font-family: monospace;
                }
            }

            .contact_container {
                width: 100%;
                text-align: center;
                height: auto;
                display: flex;
                color: whitesmoke;
                font-family: 'Alegreya Sans 400', sans-serif;
                letter-spacing: 2px;
                font-size: 15px;
                flex-direction: column;
                margin: 20px 0;

                .contact_content {
                    margin: 3px 0;
                }
            }
        }
    }
}