@media all and (min-width: 600px) and (orientation:landscape)
{
    /* styles here */
    .about {
        display: block;
        width: auto;
        height: auto;



        .gallery {
            width: 100%;
            height: auto;
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .fade_text_I {
                position: absolute;
                top: 500px;
                left: 400px;
                z-index: 50;
            }

            .fade_text_F {
                position: absolute;
                top: 800px;
                right: 400px;
                z-index: 50;

            }


            .fade_text_G {
                position: absolute;
                top: 1800px;
                right: 400px;
                z-index: 50;

            }
            .fade_text_S {
                position: absolute;
                top: 800px;
                left: 400px;
                z-index: 50;

            }


            .service_name {
                top: 600px;
                display: inline-block;
                color: whitesmoke;
                font-size: 100px;
                letter-spacing: 3px;
                font-family: monospace;

            }
            .work_img {
                width: 100%;
                height: auto;
                opacity: 0.8;
            }

        }


    }
}
@media (max-width: 600px) {
    .about {
        display: block;
        width: auto;
        height: auto;

        .gallery {
            width: 100%;
            height: auto;
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            overflow: hidden;

            .fade_text_I {
                position: absolute;
                top: 250px;
                left: 40px;
                z-index: 50;
            }

            .fade_text_F {
                position: absolute;
                top: 400px;
                right: 70px;
                z-index: 50;

            }


            .fade_text_G {
                position: absolute;
                top: 500px;
                right: 50px;
                z-index: 50;

            }

            .fade_text_S {
                position: absolute;
                top: 600px;
                left: 40px;
                z-index: 50;

            }


            .service_name {
                display: inline-block;
                color: whitesmoke;
                font-size: 20px;
                letter-spacing: 3px;
                font-family: monospace;

            }

            .work_img {
                width: 250%;
                height: auto;
                transform: translateX(-25%);
                opacity: 0.8;
                aspect-ratio: auto;

            }

        }
    }
}
