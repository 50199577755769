
@media (max-width: 600px) {
    .nav_bar {
        height: auto;
        width: auto;
        padding: 0.5rem 1rem;
        align-items: center;
        display: flex;
        flex-direction: column;

        .logo {
            width: auto;
            height: 7rem;
        }

        .contact_container {
            width: auto;
            height: auto;
            display: flex;
            color: whitesmoke;
            font-family: 'Alegreya Sans 900 Black', sans-serif;
            letter-spacing: 2px;
            font-size: 12px;
            flex-direction: column;
            align-items: center;

            .contact_content {
                margin: 3px;
            }
        }
    }
}

@media all and (min-width: 600px) and (orientation:landscape) {
    .nav_bar {
        height: 12vh;
        width: auto;
        padding: 0.5rem 1rem;
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-right: 5px;

        .logo {
            margin-left: 20px;
            width: auto;
            height: 100%;
        }

        .contact_container {
            margin-right: 20px;
            width: auto;
            height: auto;
            display: flex;
            color: whitesmoke;
            font-family: 'Alegreya Sans', sans-serif;
            letter-spacing: 2px;
            font-size: 30px;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-end;
        }
    }
}
