@media all and (min-width: 600px) and (orientation:landscape) {
    .contact_form {
        max-width: 100%;
        width: 500px;
        margin-top: 10px;
        height: 100%;

        .form_list {
            padding: 0;
            margin: 0;
            height: auto;
            width: auto;
            display: flex;
            justify-content: space-around;
            flex-direction: column;

            .input_name,
            .input_phone,
            .input_email {
                width: 100%;
                border: 0;
                background: rgba(87, 86, 86, 0.55);
                font-size: 20px;
                padding: 20px 25px;
                box-sizing: border-box;
                color: whitesmoke;
                font-family: 'Alegreya Sans 900 Black', sans-serif;
                margin: 10px 0;
            }


            .input_message {
                width: 100%;
                border: 0;
                background: rgba(87, 86, 86, 0.55);
                height: 70px;
                font-size: 20px;
                padding: 20px;
                min-height: 50px;
                box-sizing: border-box;
                color: whitesmoke;
                margin: 10px 0;
            }

            .input_name::placeholder,
            .input_email::placeholder,
            .input_phone::placeholder,
            .input_message::placeholder {
                color: rgba(245, 245, 245, 0.66);
                letter-spacing: 2px;
                font-family: 'Alegreya Sans 900 Black', sans-serif;
                font-size: 20px;
            }

            .input_name::selection,
            .input_email::selection,
            .input_phone::selection,
            .input_message::selection {
                background: whitesmoke;

            }

            .flat-button {
                color: rgba(245, 245, 245, 0.66);
                background: #5d9176;
                font-size: 23px;
                text-decoration: none;
                padding: 10px 12px;
                border: 1px solid #293a31;
                border-radius: 6px;
                letter-spacing: 2px;
                font-family: 'Alegreya Sans 900 Black', sans-serif;
                font-weight: bold;
                width: auto


            }

            .flat-button:hover {

                background: rgba(98, 154, 125, 0.7);
            }
        }

    }

}
@media (max-width: 600px) {
    .contact_form {
        width: 250px;
        margin-top: 10px;
        height: 100%;

        .form_list {
            padding: 0;
            margin: 0;
            height: auto;
            width: auto;

            .form_item {
                padding: 0;
                list-style: none;
                margin: 0 0 15px;
                display: block;
            }

            .input_name,
            .input_phone,
            .input_email {
                width: 100%;
                border: 0;
                background: rgba(87, 86, 86, 0.55);
                height: 25px;
                font-size: 10px;
                padding: 2px 20px;
                box-sizing: border-box;
                color: whitesmoke;
                font-family: 'Alegreya Sans 900 Black', sans-serif;
                margin-top: 10px;
            }


            .input_message {
                margin-top: 10px;
                width: 100%;
                border: 0;
                background: rgba(87, 86, 86, 0.55);
                height: 25px;
                font-size: 10px;
                padding: 20px;
                min-height: 50px;
                box-sizing: border-box;
                color: whitesmoke;
            }

            .input_name::placeholder,
            .input_email::placeholder,
            .input_phone::placeholder,
            .input_message::placeholder {
                color: rgba(245, 245, 245, 0.66);
                letter-spacing: 2px;
                font-family: 'Alegreya Sans 900 Black', sans-serif;
                font-size: 10px;
            }

            .input_name::selection,
            .input_email::selection,
            .input_phone::selection,
            .input_message::selection {
                background: whitesmoke;

            }

            .flat-button {
                color: rgba(245, 245, 245, 0.66);
                background: #5d9176;
                font-size: 12px;
                text-decoration: none;
                padding: 10px 12px;
                border: 1px solid #293a31;
                border-radius: 6px;
                letter-spacing: 2px;
                font-family: 'Alegreya Sans 900 Black', sans-serif;
                font-weight: bold;
                width: auto;
                margin-top: 10px;

            }

            .flat-button:hover {

                background: rgba(98, 154, 125, 0.7);
            }
        }

    }
}